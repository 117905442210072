import React, { useState } from 'react'

import Page from '@atoms/Page'
import Table, { SortDirections } from '@firstbase/components/atoms/Table'

import { Box, Button, IconButton } from '@mui/material'

import { ReactComponent as PencilEditIcon } from '@firstbase/assets/PencilEdit.svg'
import AddIcon from '@mui/icons-material/Add'
import { GET_ALL_SKUS } from '@firstbase/data/SKU/queries'

import useTableGraphqlQuery from '@firstbase/hooks/useTableGraphqlQuery'
import {
  getAllSKUs as getAllSKUsType,
  getAllSKUsVariables,
  getAllSKUs_getAllSKUs_data,
} from '@firstbase/data/SKU/__generated__/getAllSKUs'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { ColumnI } from '@firstbase/components/atoms/Table/types'
import { useApolloClient } from '@apollo/client'
import GlobalProductAction from './GlobalProductAction'
import SearchBox from '@firstbase/components/atoms/SearchBox'
import { Role, useAuthState } from '@firstbase/context/auth/AuthProvider'
import ProtectedChildren from '@firstbase/components/atoms/ProtectedChildren'
import { getSKUStatusColor } from '@firstbase/components/atoms/StatusChip/getColors'
import { sentenceCase } from 'change-case'
import { useFeatureFlag } from '@firstbase/hooks/useFeatureFlag'
import FLAGS from '@firstbase/constants/featureFlags'
import FloatingContainer from '@firstbase/components/atoms/FloatingContainer'
import { useHistory } from 'react-router-dom'

const columns = (
  canEditProduct: boolean,
  handleEditProduct: (product: getAllSKUs_getAllSKUs_data) => void
) =>
  [
    {
      header: 'Image',
      id: 'image',
      cell: {
        value: ({ skuImages }: { skuImages: string[] }) =>
          skuImages?.length ? (
            <img style={{ width: '100px' }} src={skuImages[0]} />
          ) : null,
      },
    },
    {
      header: 'Product ID',
      id: 'productID',
      cell: {
        as: cellComponents.ID,
        value: ({ id }: getAllSKUs_getAllSKUs_data) => id,
      },
    },
    {
      header: 'MPN',
      id: 'vendorSku',
      cell: {
        value: ({ vendorSku }: getAllSKUs_getAllSKUs_data) => vendorSku,
      },
    },
    {
      header: 'SKU Status',
      id: 'skuStatus',
      cell: {
        as: cellComponents.Chip,
        asProps: ({ status }: getAllSKUs_getAllSKUs_data) => ({
          color: getSKUStatusColor(status),
        }),
        value: ({ status }: getAllSKUs_getAllSKUs_data) => sentenceCase(status),
      },
    },
    {
      header: 'Product title',
      id: 'skuInformation.productTitle',
      defaultSort: SortDirections.asc,
      cell: {
        value: ({ skuInformation }: getAllSKUs_getAllSKUs_data) =>
          skuInformation.productTitle,
      },
    },
    {
      header: 'Category Name',
      id: 'categoryName',
      cell: {
        value: ({ category }: getAllSKUs_getAllSKUs_data) =>
          category.nameSingular,
      },
    },
    {
      header: 'Category ID',
      id: 'categoryId',
      cell: {
        value: ({ category }: getAllSKUs_getAllSKUs_data) => category.id,
      },
    },
    {
      header: 'Vendor Name',
      id: 'vendorName',
      cell: {
        value: ({ vendor }: getAllSKUs_getAllSKUs_data) => vendor.name,
      },
    },
    {
      header: 'Vendor Code',
      id: 'vendorCode',
      cell: {
        value: ({ vendor }: getAllSKUs_getAllSKUs_data) => vendor.code,
      },
    },
    {
      header: 'Vendor ID',
      id: 'vendorId',
      cell: {
        as: cellComponents.ID,
        value: ({ vendor }: getAllSKUs_getAllSKUs_data) => vendor.id,
      },
    },
    ...(canEditProduct
      ? [
          {
            id: 'editAction',
            sticky: 'end',
            cell: {
              value: (product: getAllSKUs_getAllSKUs_data) => (
                <IconButton onClick={() => handleEditProduct(product)}>
                  <PencilEditIcon width="1.25rem" height="1.25rem" />
                </IconButton>
              ),
            },
          },
        ]
      : []),
  ] as ColumnI[]

const Products = () => {
  const apolloClient = useApolloClient()
  const { hasRole } = useAuthState()
  const [productToEdit, setProductToEdit] = useState<
    getAllSKUs_getAllSKUs_data | undefined
  >()
  const [searchTerm, setSearchTerm] = useState('')
  const [globalProductActionOpen, setGlobalProductActionOpen] =
    useState<number>(0)
  const history = useHistory()

  const query = useTableGraphqlQuery<getAllSKUsType, getAllSKUsVariables>(
    GET_ALL_SKUS,
    {
      variables: {
        searchTerm,
        filter: { excludeCategories: ['WARRANTY'] },
      } as unknown as getAllSKUsVariables,
    },
    ({ getAllSKUs }) => getAllSKUs
  )
  const canEditProduct = hasRole(Role.Admin)

  const handleCloseGlobalProductAction = (shouldRefreshList?: boolean) => {
    setGlobalProductActionOpen(0)
    setProductToEdit(undefined)

    if (shouldRefreshList) {
      apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'getAllSKUs' })
    }
  }
  const standardCatalogM1Flag = useFeatureFlag(
    FLAGS.SE_4591_STANDARD_CATALOG_M1
  )

  const handleEditProduct = (product: getAllSKUs_getAllSKUs_data) => {
    if (standardCatalogM1Flag) {
      history.push(`/platform/products/${product.id}`)
    } else {
      setProductToEdit(product)
      setGlobalProductActionOpen(Math.random() + 1)
    }
  }

  const renderSeparateButtonsFlow = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => history.push('/platform/products/new_generic')}
        >
          Add Generic SKU
        </Button>
        <Button
          variant="contained"
          onClick={() => history.push('/platform/products/new')}
        >
          <AddIcon /> Add Product
        </Button>
      </Box>
    )
  }

  const renderButtons = () => {
    if (standardCatalogM1Flag) return renderSeparateButtonsFlow()

    return (
      <Button
        variant="contained"
        onClick={() => setGlobalProductActionOpen(Math.random() + 1)}
      >
        <AddIcon /> Add Product
      </Button>
    )
  }

  const renderTitleAction = () => (
    <ProtectedChildren hasRole={Role.Admin}>
      {renderButtons()}
    </ProtectedChildren>
  )

  return (
    <>
      <Page.TitleSection
        sectionTitle="Products"
        sectionSubtitle="List of products that are available on the platform"
        subtitleAction={renderTitleAction}
      />
      <SearchBox
        placeholder="Search products"
        disabled={(query.isLoading && !query.isPreviousData) || !!query.error}
        onChange={setSearchTerm}
        label="Search Products"
      />
      <FloatingContainer>
        <Table
          defaultSortId="skuInformation.productTitle"
          query={{ ...query, variables: { searchTerm } }}
          tableId="products"
          columns={columns(canEditProduct, handleEditProduct)}
          customRowsPerPageOptions={[500, 1000, 2000]}
          pagination
        />
      </FloatingContainer>
      {!!globalProductActionOpen && (
        <GlobalProductAction
          open={!!globalProductActionOpen}
          key={globalProductActionOpen}
          handleClose={handleCloseGlobalProductAction}
          product={productToEdit}
        />
      )}
    </>
  )
}

export default Products
