import React, { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Skeleton, Grid, Box } from '@mui/material'

import {
  GET_ALL_WARRANTY_SKU_METADATA_OPTION_VALUES,
  getAllWarrantySKUMetadataOptionValues,
  getAllWarrantySKUMetadataOptionValuesVariables,
} from '@firstbase/data/Warranties'
import { SkuMetadataFieldName } from '@globalTypes'

import { CommonProps } from '../types'
import { groupMetadataFieldsByGroup } from '../utils'

import { FieldSelect } from './FieldSelect'
import { FieldSelectCreateable } from './FieldSelectCreateable'

interface OwnProps extends CommonProps {
  setRequiredMetadataFields: React.Dispatch<React.SetStateAction<string[]>>
}

export const WarrantySkuMetadataFields = ({
  state,
  dispatch,
  setRequiredMetadataFields,
}: OwnProps) => {
  const { data, loading } = useQuery<
    getAllWarrantySKUMetadataOptionValues,
    getAllWarrantySKUMetadataOptionValuesVariables
  >(GET_ALL_WARRANTY_SKU_METADATA_OPTION_VALUES, {
    variables: { categoryCode: state.category, vendorCode: state.vendor.code },
    // Quick way of handling edge case where the user has created a new warranty type and configured the metadata fields
    fetchPolicy: 'cache-and-network',
  })

  const { vendor, category } = state

  /**
   * HACK - Allow the parent to see the required metadata fields. We could move the query
   * to the parent and pass down the required fields, but this is a quick solution. Feel free
   * to refactor this later.
   */
  useEffect(() => {
    if (data?.getAllWarrantySKUMetadataOptionValues) {
      setRequiredMetadataFields(
        data.getAllWarrantySKUMetadataOptionValues
          .filter((field) => field.required)
          .map((field) => field.id)
      )
    }
  }, [data?.getAllWarrantySKUMetadataOptionValues, setRequiredMetadataFields])

  if (loading || !data?.getAllWarrantySKUMetadataOptionValues)
    return (
      <TwoColumnGrid>
        <Skeleton variant="rectangular" height={40} />
        <Skeleton variant="rectangular" height={40} />
      </TwoColumnGrid>
    )

  return (
    <>
      {groupMetadataFieldsByGroup(
        data.getAllWarrantySKUMetadataOptionValues
      ).map((group) => (
        <Box key={`${group[0].group}-${vendor}-${category}`}>
          <TwoColumnGrid>
            {group.map((field) => {
              const reactKey = `${field.id}-${vendor}-${category}`
              const value = state.metadataFields[field.id]?.value ?? ''
              const isRegionField =
                field.name === SkuMetadataFieldName.WARRANTY_REGION

              return !field.editable || isRegionField ? (
                <FieldSelect
                  key={reactKey}
                  field={field}
                  value={value}
                  dispatch={dispatch}
                  multiple={isRegionField}
                />
              ) : (
                <FieldSelectCreateable
                  key={reactKey}
                  field={field}
                  value={value}
                  dispatch={dispatch}
                />
              )
            })}
          </TwoColumnGrid>
        </Box>
      ))}
    </>
  )
}

interface TwoColumnGridProps {
  children: React.ReactNode
}

const TwoColumnGrid = ({ children }: TwoColumnGridProps) => {
  return (
    <Grid container spacing={2}>
      {React.Children.map(children, (child) => (
        <Grid item xs={6}>
          {child}
        </Grid>
      ))}
    </Grid>
  )
}
