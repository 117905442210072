import React from 'react'

import { useMutation } from '@apollo/client'
import { CREATE_SKU } from '@firstbase/data/SKU/mutations'
import { useSnackbar } from 'notistack'
import GlobalProductForm, {
  Product,
} from './GlobalProductForm/GlobalProductForm'
import { GlobalProductActionProps } from './GlobalProductAction'
import {
  createSku,
  createSkuVariables,
} from '@firstbase/data/SKU/__generated__/createSku'
import { useUpdateStandardCatalogListing } from '@firstbase/views/Home/Platform/Products/hooks/useUpdateStandardCatalogListing'
import { format } from 'date-fns'
import { createSkuDefaultValues } from '@firstbase/constants/createSkuDefaultValues'

const AddGlobalProduct = ({
  handleClose,
  open,
  staticGenericCategory,
}: GlobalProductActionProps) => {
  const [createSKU, { loading: saving, error }] = useMutation<
    createSku,
    createSkuVariables
  >(CREATE_SKU)
  const { enqueueSnackbar } = useSnackbar()
  const [
    updateStandardCatalogListing,
    {
      loading: updatingStandardCatalogListing,
      error: errorUpdatingStandardCatalogListing,
    },
  ] = useUpdateStandardCatalogListing()

  const generateVendorSku = () => {
    const currentDate = new Date()
    const formattedDate = format(currentDate, 'yyyyMMdd')
    const random4DigitNumber = Math.floor(1000 + Math.random() * 9000)

    return `UNKNOWN-${formattedDate}-${random4DigitNumber}`
  }

  // Add mpn as vendorSku and partNumber if exists
  const getVendorValues = (
    genericCategory: boolean,
    vendor: string,
    vendorSku?: string
  ) => {
    if (genericCategory) return {}
    return {
      vendorCode: vendor,
      vendorSku: vendorSku || generateVendorSku(),
      partNumber: vendorSku || null,
    }
  }

  const handlePostCreate = async (
    productId: string,
    title: String,
    standardCatalog?: boolean
  ) => {
    if (standardCatalog !== undefined) {
      await updateStandardCatalogListing(productId, standardCatalog)
    }

    enqueueSnackbar(
      `Successfully created new product ${title}. ID is ${productId}`,
      {
        autoHideDuration: 10000,
      }
    )
    handleClose(true)
  }

  const handleCreate = (
    {
      vendor,
      title,
      category,
      image,
      genericCategory,
      vendorSku,
      alternativeMpn,
      upc,
      ean,
      standardPricing,
      metadata,
    }: Product,
    standardCatalog?: boolean
  ) => {
    const variables: createSkuVariables = {
      createSku: {
        productTitle: title,
        categoryCode: category,
        skuImages: [image],
        genericCategory,
        standardPricing,
        ...getVendorValues(genericCategory, vendor, vendorSku),
        ...createSkuDefaultValues,
        alternativeMpn: alternativeMpn,
        upc: upc,
        ean: ean,
        metadata,
      },
    }
    createSKU({
      variables,
    }).then(({ data: createdSku }) => {
      if (createdSku)
        handlePostCreate(createdSku.createSKU.id, title, standardCatalog)
    })
  }

  return (
    <GlobalProductForm
      handleClose={handleClose}
      handleSubmit={handleCreate}
      open={open}
      saving={saving || updatingStandardCatalogListing}
      error={error?.message || errorUpdatingStandardCatalogListing?.message}
      staticGenericCategory={staticGenericCategory}
    />
  )
}

export default AddGlobalProduct
