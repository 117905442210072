import React from 'react'

import { sentenceCase } from 'change-case'
import { SortDirections } from '@firstbase/components/atoms/Table'
import { interpolateQueryParamsInPath } from '@firstbase/routes/routeUtils'
import { Link } from 'react-router-dom'
import { Product } from '../Product/AddProduct/AddProduct'
import { cellComponents } from '@firstbase/components/atoms/Table/cellComponents/cellComponents'
import { getSKUStatusColor } from '@firstbase/components/atoms/StatusChip/getColors'

type Params = {
  clientId: string
  productIsLink?: boolean
  displayStatus?: boolean
}

export function generateColumns({
  clientId,
  productIsLink = true,
  displayStatus = false,
}: Params) {
  return [
    {
      header: 'Product ID',
      id: 'id',
      cell: {
        as: cellComponents.ID,
        value: ({ id }: Product) => id,
      },
    },
    {
      header: 'MPN',
      id: 'vendorSku',
      cell: {
        as: cellComponents.ID,
        value: ({ vendorSku }: Product) => vendorSku,
      },
    },
    {
      header: 'Product title',
      defaultSort: SortDirections.asc,
      id: 'title',
      cell: {
        sx: {
          minWidth: '15rem',
        },
        value: ({ title, id }: Product) =>
          productIsLink ? (
            <Link
              to={interpolateQueryParamsInPath('product', {
                clientId,
                productId: id,
              })}
            >
              {title}
            </Link>
          ) : (
            <>{title}</>
          ),
      },
    },
    {
      header: 'Category',
      defaultSort: SortDirections.asc,
      id: 'category',
      cell: { value: ({ category }: Product) => <>{category}</> },
    },
    {
      header: 'Brand',
      defaultSort: SortDirections.asc,
      id: 'brand',
      cell: { value: ({ brand }: Product) => <>{brand}</> },
    },
    ...(displayStatus
      ? [
          {
            header: 'SKU Status',
            defaultSort: SortDirections.asc,
            id: 'status',
            cell: {
              as: cellComponents.Chip,
              asProps: ({ status }: Product) => ({
                color: getSKUStatusColor(status),
              }),
              value: ({ status }: Product) => sentenceCase(status),
            },
          },
        ]
      : []),
  ]
}
